import { Component, OnInit } from '@angular/core';
import { Instance } from '../instance';
import { InstanceService } from '../instance.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-instances',
  templateUrl: './instances.component.html',
  styleUrls: ['./instances.component.css']
})
export class InstancesComponent implements OnInit {

  instances: Instance[];

  prototype_id = 1;
  onlyForInstance = false;
  owner: number;

  constructor(
    private instanceService: InstanceService,
    private location: Location,
    private route: ActivatedRoute
  ) {
    route.queryParams.subscribe(value => {
      this.onlyForInstance = !!this.route.snapshot.queryParamMap.get('onlyForInstance');
      this.owner = +this.route.snapshot.queryParamMap.get('owner');
      this.prototype_id = +this.route.snapshot.queryParamMap.get('prototype_id') || 1;
      this.initPage();
    });
  }

  ngOnInit(): void {

  }

  initPage(): void {
    this.getInstances();
  }

  getInstances(): void {
    this.instanceService.getInstances(this.prototype_id, this.owner)
      .subscribe(instances => this.instances = instances);
  }

  delete(instance: Instance): void {
    this.instances = this.instances.filter(value => value !== instance);
    this.instanceService.deleteInstance(instance).subscribe();
  }

  goBack(): void {
    this.location.back();
  }

}
