import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() {
  }

  http(): any {
    return {
      api: {
        baseUrl: ''
        // baseUrl: 'http://localhost:3010'
      }
    };
  }

  constant(): any {
    return {
      prototype: {
        property: {
          default_value_type: [
            {
              name: '请选择值类型',
              value: 0
            },
            {
              name: '字符串',
              value: 1
            },
            {
              name: '数组（对象）',
              value: 2
            },
          ]
        }
      }
    };
  }
}
