import { Injectable } from '@angular/core';
import { MessageService } from './message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';
import { BackstageUser } from './backstage-user';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private commonUrl = `${this.configService.http().api.baseUrl}`;

  constructor(
    private messageService: MessageService,
    private httpClient: HttpClient,
    private configService: ConfigService
  ) {
  }

  private httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  login(bu: BackstageUser): Observable<string> {
    return this.httpClient.post<string>(`${this.commonUrl}/login`, bu, this.httpOptions).pipe(
      tap(_ => this.log(`${bu.username} login`)),
      catchError(this.handleError<string>('login'))
    );
  }

  private log(message: string): void {
    this.messageService.add(`InstanceService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  public toggle(panes: Panes, current?: string): void {
    const currentState = !!panes[current];
    Object.keys(panes).forEach(pane => {
      panes[pane] = false;
    });
    panes[current] = current && !currentState;
  }

}

export interface Panes {
  [key: string]: boolean;
}
