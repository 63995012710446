import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// import { InMemoryDataService } from './in-memory-data.service';

import { AppComponent } from './app.component';
import { PrototypeComponent } from './prototype/prototype.component';
import { PrototypeDetailComponent } from './prototype-detail/prototype-detail.component';
import { MessagesComponent } from './messages/messages.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PrototypeSearchComponent } from './prototype-search/prototype-search.component';
import { InstancesComponent } from './instances/instances.component';
import { InstanceDetailComponent } from './instance-detail/instance-detail.component';
import { LoginComponent } from './login/login.component';
import { JwtModule } from '@auth0/angular-jwt';
import { NavigationComponent } from './navigation/navigation.component';
import { ApiInterceptor } from './api.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SkinsComponent } from './game/skins/skins.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { SkinsEditorComponent } from './@dialog/skins-editor/skins-editor.component';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ToBooleanPipe } from './@pipe/to-boolean.pipe';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { ExistPipe } from './@pipe/exist.pipe';
import { ReadablePipe } from './@pipe/readable.pipe';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    PrototypeComponent,
    PrototypeDetailComponent,
    MessagesComponent,
    DashboardComponent,
    PrototypeSearchComponent,
    InstancesComponent,
    InstanceDetailComponent,
    LoginComponent,
    NavigationComponent,
    SkinsComponent,
    SkinsEditorComponent,
    ToBooleanPipe,
    ExistPipe,
    ReadablePipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem('token'),
        allowedDomains: ['localhost:3010', '13.125.235.191:8888', 'watermelonapi.com'],
        headerName: 'Authorization',
        authScheme: 'Bearer ',
        throwNoTokenError: false,
        disallowedRoutes: [/\/login/g]
      }
    }),
    BrowserAnimationsModule,

    // The HttpClientInMemoryWebApiModule module intercepts HTTP requests
    // and returns simulated server responses.
    // Remove it when a real server is ready to receive requests.
    // HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {dataEncapsulation: false})

    MatSliderModule, MatTableModule, MatCardModule,
    MatPaginatorModule, MatButtonModule, MatDialogModule,
    MatInputModule, MatRadioModule, MatSelectModule,
    MatIconModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true, disableClose: false}}
  ],
  bootstrap: [AppComponent],
  entryComponents: [SkinsEditorComponent]
})
export class AppModule {
}
