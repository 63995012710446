export class Paginator<T> {
  constructor(
    public length = 0,
    public pageSize = 10,
    public pageIndex = 0,
    public pageSizeOptions = [5, 10, 25, 50, 100],
    public data?: T
  ) {
  }
}
