import { Component, OnInit, Input } from '@angular/core';
import { Prototype, PrototypeDetail } from '../prototype';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { PrototypeService } from '../prototype.service';
import { InstanceService } from '../instance.service';
import { Instance } from '../instance';
import { ConfigService } from '../config.service';
import { CommonService, Panes } from '../common.service';
import { SyncBill } from '../sync-bill';

@Component({
  selector: 'app-prototype-detail',
  templateUrl: './prototype-detail.component.html',
  styleUrls: ['./prototype-detail.component.css']
})
export class PrototypeDetailComponent implements OnInit {

  prototype?: Prototype = {};

  default_value_type_config = this.configService.constant().prototype.property.default_value_type;

  toggle: Panes = {
    showNewInstancePane: false,
    showSynchronousPane: false
  };

  sync_bill: SyncBill = {
    add: true
  };

  newInstance: Instance;

  onlyForInstance = false;
  owner: number;
  fromProperty = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private prototypeService: PrototypeService,
    private location: Location,
    private instanceService: InstanceService,
    private configService: ConfigService,
    private commonService: CommonService,
  ) {
    route.params.subscribe(value => {
      this.onlyForInstance = !!this.route.snapshot.queryParamMap.get('onlyForInstance');
      this.fromProperty = !!this.route.snapshot.queryParamMap.get('fromProperty');
      this.owner = +this.route.snapshot.queryParamMap.get('owner');
      this.initPage();
    });
  }

  ngOnInit(): void {
  }

  initPage(): void {
    this.getPrototype();
    this.commonService.toggle(this.toggle);
  }

  getPrototype(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.prototypeService.getPrototype(id)
      .subscribe(prototype => this.prototype = prototype);
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    this.prototypeService
      .updatePrototype(this.prototype)
      .subscribe();
  }

  saveDetail(detail: PrototypeDetail): void {
    this.prototypeService
      .saveDetail(detail)
      .subscribe(value => {
        if (value && value.id) {
          detail.id = value.id;
        }
      });
  }

  createDetail(): void {
    this.prototype.details.push({
      prototype_id: this.prototype.id,
      date: new Date(),
      default_value_type: 0
    });
  }

  deleteDetail(detail: PrototypeDetail): void {
    this.prototype.details = this.prototype.details.filter(value => value !== detail);
    if (detail.id) {
      this.prototypeService
        .deleteDetail(detail)
        .subscribe();
    }
  }

  addNewInstance(): void {
    this.commonService.toggle(this.toggle, 'showNewInstancePane');
    this.newInstance = {
      name: this.prototype.name + '-',
      key: `${this.prototype.key}-${this.owner ? this.owner + '-' : ''}`,
      prototype_id: this.prototype.id,
      proportion: 100,
      instance_type: this.prototype.prototype_type,
      owner: this.owner || null
    };
  }

  saveNewInstance(): void {
    this.instanceService.newInstance(this.prototype, this.newInstance).subscribe(value => {
      if (value.id) {
        this.commonService.toggle(this.toggle);
        return this.router.navigateByUrl(`/instances/${value.id}`);
      }
    });
  }

  addSynchronousConfig(): void {
    this.commonService.toggle(this.toggle, 'showSynchronousPane');
  }

  beginSynchronous(): void {
    this.prototypeService.beginSynchronous(this.sync_bill, this.prototype).subscribe(success => {
      if (success) {
        this.commonService.toggle(this.toggle);
      }
    });
  }

  navigateToPrototypeDetail(detail: PrototypeDetail): void {
    this.prototypeService.getPrototypeId(this.prototype.id, detail.id)
      .subscribe(id => {
        if (id) {
          return this.router.navigate([`/prototype/detail/${id}`], {
            queryParams: {
              fromProperty: true
            }
          });
        }
      });
  }
}
