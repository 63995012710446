import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { InstanceService } from '../instance.service';
import { Instance } from '../instance';
import { InstanceDetail } from '../instance';
import { ConfigService } from '../config.service';
import { PrototypeService } from '../prototype.service';

@Component({
  selector: 'app-instance-detail',
  templateUrl: './instance-detail.component.html',
  styleUrls: ['./instance-detail.component.css']
})
export class InstanceDetailComponent implements OnInit {

  instance?: Instance = {};

  default_value_type_config = this.configService.constant().prototype.property.default_value_type;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private instanceService: InstanceService,
    private prototypeService: PrototypeService,
    private configService: ConfigService,
  ) {
  }

  ngOnInit(): void {
    this.getInstance();
  }

  getInstance(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.instanceService.getInstance(id)
      .subscribe(instance => this.instance = instance);
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    this.instanceService
      .updateInstance(this.instance)
      .subscribe();
  }

  saveDetail(detail: InstanceDetail): void {
    this.instanceService
      .saveDetail(detail)
      .subscribe();
  }

  deleteDetail(detail: InstanceDetail): void {
    this.instance.details = this.instance.details.filter(value => value !== detail);
    if (detail.id) {
      this.instanceService
        .deleteDetail(detail)
        .subscribe();
    }
  }

  navigateToInstanceList(detail: InstanceDetail): void {
    this.prototypeService.getPrototypeId(detail.prototype_id, detail.prototype_property_id)
      .subscribe(id => {
        if (id) {
          return this.router.navigate(['/instances'], {
            queryParams: {
              prototype_id: id,
              onlyForInstance: true,
              owner: detail.id
            }
          });
        }
      });
  }

  navigateToInstanceCreate(detail: InstanceDetail): void {
    this.prototypeService.getPrototypeId(detail.prototype_id, detail.prototype_property_id)
      .subscribe(id => {
        if (id) {
          return this.router.navigate([`/prototype/detail/${id}`], {
            queryParams: {
              onlyForInstance: true,
              owner: detail.id
            }
          });
        }
      });
  }

}
