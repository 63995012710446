<h1 matDialogTitle>{{skin.name}}</h1>
<div class="mat-dialog-container">
  <mat-radio-group class="iRadio"
                   color="primary"
                   [(ngModel)]="skin.is_banned"
                   name="is_banned">
    <mat-radio-button class="iRadio" [value]="trueNumber" color="warn">禁用</mat-radio-button>
    <mat-radio-button class="iRadio" [value]="falseNumber">启用</mat-radio-button>
  </mat-radio-group>
</div>
<div class="mat-dialog-container">
  <mat-radio-group class="iRadio"
                   color="primary"
                   [(ngModel)]="skin.is_hot"
                   name="is_hot">
    <mat-radio-button class="iRadio" [value]="trueNumber" color="accent">热门</mat-radio-button>
    <mat-radio-button class="iRadio" [value]="falseNumber">非热门</mat-radio-button>
  </mat-radio-group>
</div>
<div class="mat-dialog-container" *ngIf="skin.is_hot | toBoolean">
  <mat-form-field appearance="outline">
    <mat-label>热门排序值</mat-label>
    <input matInput type="number" [(ngModel)]="skin.sort_hot">
    <mat-hint>自行排重、允许重复</mat-hint>
  </mat-form-field>
</div>

<div class="mat-dialog-container" *ngIf="true">
  <mat-form-field appearance="outline">
    <mat-label>售卖价格</mat-label>
    <input matInput type="number" [(ngModel)]="skin.price">
    <mat-hint>≥0</mat-hint>
  </mat-form-field>
</div>
<div matDialogActions>
  <button mat-stroked-button color="accent" (click)="close()">Cancel</button>
  <button mat-stroked-button color="primary" (click)="save()">Save</button>
</div>
