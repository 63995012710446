import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PubSubService } from '../pub-sub.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent implements OnInit {

  doesLogin = false;

  constructor(
    private router: Router,
    private pubSubService: PubSubService,
  ) {
    this.pubSubService.messageObserve.subscribe(msg => {
      this.doesLogin = msg;
    });
  }

  ngOnInit(): void {

  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['/login']).then(() => {
      this.doesLogin = false;
    }).catch(console.log);
  }

}
