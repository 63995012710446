import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Prototype, PrototypeDetail } from './prototype';
import { MessageService } from './message.service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';
import { SyncBill } from './sync-bill';


@Injectable({
  providedIn: 'root'
})
export class PrototypeService {

  private prototypesUrl = `${this.configService.http().api.baseUrl}/api/groupTest/prototypes`;
  private prototypesDetailUrl = `${this.configService.http().api.baseUrl}/api/groupTest/prototypes/detail`;
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  constructor(
    private messageService: MessageService,
    private httpClient: HttpClient,
    private configService: ConfigService
  ) {
  }

  getPrototypes(type?: number): Observable<Prototype[]> {
    const params: any = {};
    if (type) {
      params.prototype_type = type;
    }

    return this.httpClient
      .get<Prototype[]>(this.prototypesUrl, {
        params
      })
      .pipe(
        tap(_ => this.log('fetched prototypes')),
        catchError(this.handleError<Prototype[]>('getPrototypes', []))
      );
  }

  getPrototype(id: number): Observable<Prototype> {
    const url = `${this.prototypesUrl}/${id}`;
    return this.httpClient
      .get<Prototype>(url)
      .pipe(
        tap(_ => this.log(`fetched prototype id=${id}`)),
        catchError(this.handleError<Prototype>(`getPrototype id=${id}`))
      );
  }

  updatePrototype(prototype: Prototype): Observable<any> {
    return this.httpClient
      .put(this.prototypesUrl, prototype, this.httpOptions)
      .pipe(
        tap(_ => this.log(`updated prototype id=${prototype.id}`)),
        catchError(this.handleError<Prototype>(`updatePrototype id=${prototype.id}`))
      );
  }

  addPrototype(prototype: Prototype): Observable<Prototype> {
    return this.httpClient
      .post<Prototype>(this.prototypesUrl, prototype, this.httpOptions)
      .pipe(
        tap((newPrototype: Prototype) => this.log(`added prototype w/ id=${newPrototype.id}`)),
        catchError(this.handleError<Prototype>(`addPrototype`))
      );
  }

  deletePrototype(prototype: Prototype | number): Observable<Prototype> {
    const id = typeof prototype === 'number' ? prototype : prototype.id;
    const url = `${this.prototypesUrl}/${id}`;

    return this.httpClient.delete<Prototype>(url, this.httpOptions).pipe(
      tap(_ => this.log(`deleted prototype id=${id}`)),
      catchError(this.handleError<Prototype>('deletePrototype'))
    );
  }

  searchPrototypes(term: string): Observable<Prototype[]> {
    if (!term.trim()) {
      return of([]);
    }

    return this.httpClient.get<Prototype[]>(`${this.prototypesUrl}/?name=${term}`).pipe(
      tap(x => x.length ?
        this.log(`found prototypes matching "${term}"`) :
        this.log(`no prototypes matching "${term}"`)),
      catchError(this.handleError<Prototype[]>('searchPrototypes', []))
    );
  }

  saveDetail(detail: PrototypeDetail): Observable<PrototypeDetail> {
    return this.httpClient.post<PrototypeDetail>(this.prototypesDetailUrl, detail, this.httpOptions).pipe(
      tap(_ => this.log(`saved prototype detail key="${detail.key}"`)),
      catchError(this.handleError<PrototypeDetail>('savePrototypeDetail'))
    );
  }

  deleteDetail(detail: PrototypeDetail): Observable<PrototypeDetail> {
    const url = `${this.prototypesDetailUrl}/${detail.id}`;
    return this.httpClient.delete<PrototypeDetail>(url, this.httpOptions).pipe(
      tap(_ => this.log(`deleted prototype detail id=${detail.id}`)),
      catchError(this.handleError<Prototype>('deletePrototypeDetail'))
    );
  }

  getPrototypeId(prototypeId: number, detailId: number): Observable<number> {
    const url = `${this.prototypesDetailUrl}/${prototypeId}/${detailId}`;
    return this.httpClient.get<number>(url).pipe(
      tap(_ => this.log(`get new prototype id, from ${prototypeId}-${detailId}`)),
      catchError(this.handleError<number>('getPrototypeId', 0))
    );
  }

  beginSynchronous(bill: SyncBill = {add: true}, prototype: Prototype): Observable<boolean> {
    const url = `${this.prototypesUrl}/sync`;
    return this.httpClient.post<boolean>(url, {bill, prototype}, this.httpOptions).pipe(
      tap(_ => this.log(`sync from bill: ${JSON.stringify(bill)}`)),
      catchError(this.handleError<boolean>('getPrototypeId', false))
    );
  }

  private log(message: string): void {
    this.messageService.add(`PrototypeService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.url}: ${error.status} ${error.error.message || error.statusText} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
