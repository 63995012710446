import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Instance, InstanceDetail } from './instance';
import { MessageService } from './message.service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Prototype, PrototypeDetail } from './prototype';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class InstanceService {

  private instancesUrl = `${this.configService.http().api.baseUrl}/api/groupTest/instances`;
  private instanceDetailUrl = `${this.configService.http().api.baseUrl}/api/groupTest/instances/detail`;
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  constructor(
    private messageService: MessageService,
    private httpClient: HttpClient,
    private configService: ConfigService
  ) {
  }

  getInstances(id?: number, owner?: number): Observable<Instance[]> {
    const params: any = {};
    if (id) {
      params.prototype_id = id;
    }
    if (owner) {
      params.owner = owner;
    }
    return this.httpClient
      .get<Instance[]>(this.instancesUrl, {params})
      .pipe(
        tap(_ => this.log('fetched instances')),
        catchError(this.handleError<Prototype[]>('getInstances', []))
      );
  }

  newInstance(prototype: Prototype, instance: Instance): Observable<Instance> {
    return this.httpClient.post<Instance>(this.instancesUrl, {prototype, instance}, this.httpOptions).pipe(
      tap((newOne: Instance) => this.log(`added instance w/ id=${newOne ? newOne.id : ''}`)),
      catchError(this.handleError<Instance>(`Generate Instance`, {}))
    );
  }

  deleteInstance(instance: Instance): Observable<any> {
    const url = `${this.instancesUrl}/${instance.id}`;
    return this.httpClient.delete<Instance>(url, this.httpOptions).pipe(
      tap(_ => this.log(`deleted instance key='${instance.key}'`)),
      catchError(this.handleError<Instance>('deleteInstance'))
    );
  }

  getInstance(id: number): Observable<Instance> {
    const url = `${this.instancesUrl}/${id}`;
    return this.httpClient
      .get<Instance>(url)
      .pipe(
        tap(_ => this.log(`fetched instance id=${id}`)),
        catchError(this.handleError<Instance>(`getInstance`))
      );
  }

  updateInstance(instance: Instance): Observable<any> {
    return this.httpClient
      .put(this.instancesUrl, instance, this.httpOptions)
      .pipe(
        tap(_ => this.log(`updated instance id=${instance.id}`)),
        catchError(this.handleError<Instance>(`updateInstance id=${instance.id}`))
      );
  }

  saveDetail(detail: InstanceDetail): Observable<InstanceDetail> {
    return this.httpClient.post<InstanceDetail>(this.instanceDetailUrl, detail, this.httpOptions).pipe(
      tap(_ => this.log(`saved instance detail key="${detail.key}"`)),
      catchError(this.handleError<InstanceDetail>('saveInstanceDetail'))
    );
  }

  deleteDetail(detail: InstanceDetail): Observable<InstanceDetail> {
    const url = `${this.instanceDetailUrl}/${detail.id}`;
    return this.httpClient.delete<InstanceDetail>(url, this.httpOptions).pipe(
      tap(_ => this.log(`deleted instance detail id=${detail.id}`)),
      catchError(this.handleError<Instance>('deleteInstanceDetail'))
    );
  }

  private log(message: string): void {
    this.messageService.add(`InstanceService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
