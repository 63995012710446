<mat-card class="mat-card" style="margin-top: 10px">
  <mat-card-content>
    <mat-form-field appearance="outline">
      <mat-label>是否热门</mat-label>
      <mat-select [(value)]="query.is_hot" (selectionChange)="queryChange()">
        <mat-option [value]="trueNumber">热门</mat-option>
        <mat-option [value]="falseNumber">非热门</mat-option>
      </mat-select>
      <button mat-button *ngIf="query.is_hot | exist" matSuffix mat-icon-button
              (click)="query.is_hot=undefined; search($event); $event.stopPropagation()"
              color="warn">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>上下架状态</mat-label>
      <mat-select [(value)]="query.is_on_shelf" (selectionChange)="queryChange()">
        <mat-option [value]="trueNumber">上架</mat-option>
        <mat-option [value]="falseNumber">下架</mat-option>
      </mat-select>
      <button mat-button *ngIf="query.is_on_shelf | exist" matSuffix mat-icon-button
              (click)="query.is_on_shelf=undefined; search($event); $event.stopPropagation()"
              color="warn">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>皮肤名称</mat-label>
      <input matInput
             [(ngModel)]="query.name"
             type="text">
      <button mat-button *ngIf="query.name" matSuffix mat-icon-button (click)="search()" color="primary">
        <mat-icon>search</mat-icon>
      </button>
      <button mat-button *ngIf="query.name" matSuffix mat-icon-button (click)="query.name=''; search()" color="warn">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </mat-card-content>
</mat-card>


<mat-card class="mat-card">
  <!--  <mat-card-header class="mat-card-header">数据</mat-card-header>-->
  <mat-card-content>
    <div class="mat-elevation-z8">
      <!--Since the table optimizes for performance, it will not automatically check for changes to the data array.-->
      <table *ngIf="pageInfo"
             mat-table
             [dataSource]="pageInfo.data">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>名称</th>
          <td mat-cell *matCellDef="let skin">{{skin.name}}</td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>描述</th>
          <td mat-cell *matCellDef="let skin">{{skin.description}}</td>
        </ng-container>
        <ng-container matColumnDef="avatar">
          <th mat-header-cell *matHeaderCellDef>头像</th>
          <td mat-cell *matCellDef="let skin">
            <img src="{{skin.avatar}}" alt="皮肤头像" *ngIf="skin.avatar">
          </td>
        </ng-container>
        <ng-container matColumnDef="resource">
          <th mat-header-cell *matHeaderCellDef>皮肤</th>
          <td mat-cell *matCellDef="let skin">
            <a mat-stroked-button color="primary" href="{{skin.resource}}" *ngIf="skin.resource">下载</a>
          </td>
        </ng-container>
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef>价格</th>
          <td mat-cell *matCellDef="let skin">{{skin.price}}</td>
        </ng-container>
        <ng-container matColumnDef="amount_liked">
          <th mat-header-cell *matHeaderCellDef>点赞数</th>
          <td mat-cell *matCellDef="let skin">{{skin.amount_liked}}</td>
        </ng-container>
        <ng-container matColumnDef="amount_in_used">
          <th mat-header-cell *matHeaderCellDef>使用数</th>
          <td mat-cell *matCellDef="let skin">{{skin.amount_in_used}}</td>
        </ng-container>
        <ng-container matColumnDef="sort_hot">
          <th mat-header-cell *matHeaderCellDef>热门排序</th>
          <td mat-cell *matCellDef="let skin">{{skin.sort_hot}}</td>
        </ng-container>
        <ng-container matColumnDef="is_hot">
          <th mat-header-cell *matHeaderCellDef>是否热门</th>
          <td mat-cell *matCellDef="let skin">{{skin.is_hot | readable: 'hot'}}</td>
        </ng-container>
        <ng-container matColumnDef="is_public">
          <th mat-header-cell *matHeaderCellDef>用户是否公开</th>
          <td mat-cell *matCellDef="let skin">{{skin.is_public | readable: 'public'}}</td>
        </ng-container>
        <ng-container matColumnDef="is_on_shelf">
          <th mat-header-cell *matHeaderCellDef>用户是否上架</th>
          <td mat-cell *matCellDef="let skin">{{skin.is_on_shelf | readable: 'shelf'}}</td>
        </ng-container>
        <ng-container matColumnDef="is_banned">
          <th mat-header-cell *matHeaderCellDef>是否禁用</th>
          <td mat-cell *matCellDef="let skin">{{skin.is_banned | readable: 'banned'}}</td>
        </ng-container>
        <ng-container matColumnDef="is_default">
          <th mat-header-cell *matHeaderCellDef>是否系统默认</th>
          <td mat-cell *matCellDef="let skin">{{skin.is_default | readable: 'default'}}</td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>创建日期</th>
          <td mat-cell *matCellDef="let skin">{{skin.createdAt| date: 'YYYY-MM-dd HH:mm:ss'}}</td>
        </ng-container>
        <ng-container matColumnDef="updatedAt">
          <th mat-header-cell *matHeaderCellDef>更新日期</th>
          <td mat-cell *matCellDef="let skin">{{skin.updatedAt | date: 'YYYY-MM-dd HH:mm:ss'}}</td>
        </ng-container>
        <ng-container matColumnDef="operators">
          <th mat-header-cell *matHeaderCellDef>操作</th>
          <td mat-cell *matCellDef="let skin">
            <button mat-raised-button color="primary" (click)="editSkin(skin)" [disabled]="false">编辑
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
      </table>
      <mat-paginator *ngIf="pageInfo"
                     [length]="pageInfo.length"
                     [pageSize]="pageInfo.pageSize"
                     [pageIndex]="pageInfo.pageIndex"
                     [pageSizeOptions]="pageInfo.pageSizeOptions"
                     (page)="pageChange($event)">
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
