import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { BackstageUser } from '../backstage-user';
import { Router } from '@angular/router';
import { PubSubService } from '../pub-sub.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  // todo Password field is not contained in a form: (More info: https://goo.gl/9p2vKq)
  backstageUser: BackstageUser = {};

  constructor(
    private commonService: CommonService,
    private router: Router,
    private pubSubService: PubSubService,
  ) {
  }

  ngOnInit(): void {
  }

  login(): void {
    this.commonService.login(this.backstageUser).subscribe(token => {
      if (token) {
        localStorage.setItem('token', token);

        this.pubSubService.success(true);

        return this.router.navigate(['dashboard']);
      }
    }, error => {
      this.pubSubService.success(false);
    });
  }

}
