<h2>我的方案</h2>
<ul class="heroes">
  <li *ngFor="let instance of instances">
    <a routerLink="/instances/{{instance.id}}">
      <span class="badge">{{instance.id}}</span> {{instance.name}}
    </a>
    <button class="delete" title="delete Instance"
            (click)="delete(instance)">x
    </button>
    <span class="best" *ngIf="instance.is_best">√</span>
  </li>
</ul>

<div>
  <button (click)="goBack()" class="goBack">go back</button>
</div>
