<div class="login">
  <div class="oneInput">
    <label for="username">用户名：</label>
    <input id="username" type="text" [(ngModel)]="backstageUser.username">
  </div>

  <div class="oneInput">
    <label for="password">密码：</label>
    <input id="password" type="password" [(ngModel)]="backstageUser.password">
  </div>

  <div class="sub">
    <button (click)="login()">Login</button>
    <button (click)="backstageUser={}">Reset</button>
  </div>
</div>
