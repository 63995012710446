import { Component, OnInit } from '@angular/core';
import { FruitSkinService } from '../../@service/fruit-skin.service';
import { FruitSkin } from '../../@interface/fruit-skin';
import { Paginator } from '../../@class/paginator';
import { MatDialog } from '@angular/material/dialog';
import { SkinsEditorComponent } from '../../@dialog/skins-editor/skins-editor.component';

@Component({
  selector: 'app-skins',
  templateUrl: './skins.component.html',
  styleUrls: ['./skins.component.css']
})
export class SkinsComponent implements OnInit {

  trueNumber = 1;
  falseNumber = 0;

  columnsToDisplay = [
    'name', 'description', 'avatar', 'resource', 'price',
    'amount_liked', 'amount_in_used', 'sort_hot',
    'is_hot', 'is_public', 'is_on_shelf', 'is_banned', 'is_default',
    'createdAt',
    'operators'
  ];
  pageInfo = new Paginator<FruitSkin[]>();

  query: { is_hot?: number, is_on_shelf?: number, name?: string } = {};

  constructor(
    private fruitSkinService: FruitSkinService,
    private matDialog: MatDialog
  ) {
    this.pageInfo.pageSize = 5;
  }

  ngOnInit(): void {
    this.search();
  }

  search(event?): void {
    const params = Object.assign({
      pageIndex: this.pageInfo.pageIndex,
      pageSize: this.pageInfo.pageSize
    }, this.query);
    this.fruitSkinService.getAll(params).subscribe(pageInfo => {
      this.pageInfo = pageInfo;
    });
  }

  queryChange(): void {
    this.search();
  }

  pageChange(event): void {
    this.pageInfo.pageIndex = event.pageIndex;
    this.pageInfo.pageSize = event.pageSize;
    this.search();
  }

  editSkin(skin: FruitSkin): void {
    this.matDialog.open(SkinsEditorComponent, {
      data: skin,
      width: '50%',
    });
  }

}
