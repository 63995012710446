<div id="search-component">
  <label for="search-box">Prototype Search</label>
  <input #searchBox id="search-box" (input)="search(searchBox.value)"/>

  <ul class="search-result">
    <li *ngFor="let prototype of prototypes$ | async">
      <a routerLink="/prototype/detail/{{prototype.id}}">
        {{prototype.name}}
      </a>
    </li>
  </ul>
</div>
