<h2>{{prototype.name | uppercase}} Details</h2>
<div *ngIf="prototype && prototype.prototype_type==1" class="area">
  <div><span>id: </span>{{prototype.id}}</div>
  <div>
    <label for="prototype-name">方案名: </label>
    <input id="prototype-name" [(ngModel)]="prototype.name">
  </div>
  <div>
    <label for="prototype-key">标识符: </label>
    <input id="prototype-key" [(ngModel)]="prototype.key">
  </div>
  <div>
    <label for="prototype-remark">备注: </label>
    <input id="prototype-remark" [(ngModel)]="prototype.remark">
  </div>
  <div>
    <label for="prototype-open">是否启用: </label>
    <input id="prototype-open" [(ngModel)]="prototype.is_opened" type="checkbox" value="{{prototype.is_opened}}">
  </div>
  <div>
    <label for="prototype-date">日期: </label>
    <input id="prototype-date" [ngModel]="prototype.date | date: 'YYYY-MM-dd HH:mm:ss'" disabled>
  </div>

  <button (click)="save()">save</button>
</div>

<!--详细配置-->
<div class="area">
  <h2>{{prototype.name | uppercase}} Configuration Details</h2>
  <div *ngIf="prototype && prototype.details && prototype.details.length">
    <div *ngFor="let detail of prototype.details">
      <select [(ngModel)]="detail.default_value_type">
        <option *ngFor="let item of default_value_type_config" value="{{item.value}}">{{item.name}}</option>
      </select>
      <input [(ngModel)]="detail.name" placeholder="配置名">
      <input [(ngModel)]="detail.key" placeholder="标识符">
      <input [(ngModel)]="detail.default_value" placeholder="默认值" [disabled]="detail.default_value_type!=1">
      <input [(ngModel)]="detail.remark" placeholder="备注">
      <input [ngModel]="detail.date | date: 'YYYY-MM-dd HH:mm:ss'" placeholder="日期" disabled>
      <button (click)="saveDetail(detail)" *ngIf="!onlyForInstance">Save</button>
      <button (click)="deleteDetail(detail)" *ngIf="!onlyForInstance">Delete</button>
      <button *ngIf="detail.default_value_type==2 && !onlyForInstance"
              (click)="navigateToPrototypeDetail(detail)">
        配置 数组（对象）
      </button>
    </div>
  </div>
  <button (click)="createDetail()" *ngIf="!onlyForInstance">Create One</button>
</div>

<div>
  <button (click)="goBack()">go back</button>
  <button (click)="addNewInstance()"
          style="background: yellowgreen"
          *ngIf="!fromProperty">
    生成测试方案
  </button>
  <button (click)="addSynchronousConfig()"
          style="background: lightseagreen"
          *ngIf="!fromProperty">
    同步原型到现有实例
  </button>
</div>

<!--新测试方案-->
<div class="area"
     *ngIf="prototype && prototype.details && prototype.details.length && toggle.showNewInstancePane && newInstance">
  <h2>{{prototype.name | uppercase}} New Instance</h2>
  <div>
    <label for="newInstance-name">方案名: </label>
    <input id="newInstance-name" [(ngModel)]="newInstance.name" placeholder="{{prototype.name}}-">
  </div>
  <div>
    <label for="newInstance-key">标识符: </label>
    <input id="newInstance-key" [(ngModel)]="newInstance.key" placeholder="{{prototype.key}}-">
  </div>
  <div>
    <label for="newInstance-proportion">占比值: </label>
    <input id="newInstance-proportion" [(ngModel)]="newInstance.proportion" type="number">
  </div>

  <button (click)="saveNewInstance()">保存新实例</button>
</div>

<!--同步策略-->
<div class="area"
     *ngIf="prototype && prototype.details && prototype.details.length && toggle.showSynchronousPane">
  <h2>{{prototype.name | uppercase}} Synchronous</h2>
  <div>
    <label for="newInstance-sync_bill.add">同步新增项: </label>
    <input id="newInstance-sync_bill.add" [(ngModel)]="sync_bill.add" type="checkbox">
  </div>
  <div>
    <label for="newInstance-sync_bill.update">同步修改项: </label>
    <input id="newInstance-sync_bill.update" [(ngModel)]="sync_bill.update" type="checkbox" disabled>
  </div>
  <div>
    <label for="newInstance-sync_bill.delete">同步移除项: </label>
    <input id="newInstance-sync_bill.delete" [(ngModel)]="sync_bill.delete" type="checkbox" disabled>
  </div>

  <button (click)="beginSynchronous()">执行同步</button>
</div>

