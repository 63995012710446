import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PubSubService {

  constructor() {
  }

  private messageSubject = new Subject<boolean>();

  public messageObserve = this.messageSubject.asObservable();

  private setMessage(message: boolean): void {
    this.messageSubject.next(message);
  }

  public success(msg: boolean, callback?: () => void): void {
    this.setMessage(msg);
    if (callback) {
      callback();
    }
  }

}
