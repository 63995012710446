<h2>{{instance.name | uppercase}} Details</h2>
<div *ngIf="instance && instance.instance_type==1" class="area">
  <div><span>id: </span>{{instance.id}}</div>
  <div>
    <label for="instance-instance_id">原型ID: </label>
    <input id="instance-instance_id" [(ngModel)]="instance.prototype_id" disabled>
  </div>
  <div>
    <label for="instance-name">方案名: </label>
    <input id="instance-name" [(ngModel)]="instance.name">
  </div>
  <div>
    <label for="instance-key">标识符: </label>
    <input id="instance-key" [(ngModel)]="instance.key">
  </div>
  <div>
    <label for="instance-proportion">占比值: </label>
    <input id="instance-proportion" [(ngModel)]="instance.proportion" type="number">
  </div>
  <div>
    <label for="instance-remark">备注: </label>
    <input id="instance-remark" [(ngModel)]="instance.remark">
  </div>
  <div>
    <label for="instance-date">日期: </label>
    <input id="instance-date" [ngModel]="instance.date | date: 'YYYY-MM-dd HH:mm:ss'" disabled>
  </div>
  <div>
    <label for="instance-is_best">是否最佳方案: </label>
    <input id="instance-is_best" [(ngModel)]="instance.is_best" checked="{{instance.is_best}}" type="checkbox">
  </div>
  <div>
    <label for="instance-open">是否启用: </label>
    <input id="instance-open" [(ngModel)]="instance.is_opened" checked="{{instance.is_opened}}" type="checkbox">
  </div>

  <button (click)="save()">save</button>
</div>


<div class="area">
  <h2>{{instance.name | uppercase}} Configuration Details</h2>
  <div *ngIf="instance && instance.details && instance.details.length">
    <div *ngFor="let detail of instance.details">
      <select [(ngModel)]="detail.value_type" disabled>
        <option *ngFor="let item of default_value_type_config" value="{{item.value}}">{{item.name}}</option>
      </select>
      <input [(ngModel)]="detail.name" placeholder="配置名" disabled>
      <input [(ngModel)]="detail.key" placeholder="标识符" disabled>
      <input [(ngModel)]="detail.value" placeholder="实际值" [disabled]="detail.value_type!=1">
      <input [(ngModel)]=" detail.remark" placeholder="备注">
      <input [ngModel]="detail.date | date: 'YYYY-MM-dd HH:mm:ss'" placeholder="日期" disabled>
      <button (click)="saveDetail(detail)">Save</button>
      <button *ngIf="detail.value_type==2" (click)="navigateToInstanceList(detail)">实例列表</button>
      <button *ngIf="detail.value_type==2" (click)="navigateToInstanceCreate(detail)">创建实例</button>
    </div>
  </div>
</div>

<div>
  <button (click)="goBack()">go back</button>
</div>





