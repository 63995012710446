<h2>My Prototypes</h2>
<ul class="heroes">
  <li *ngFor="let prototype of prototypes">
    <a routerLink="/prototype/detail/{{prototype.id}}">
      <span class="badge">{{prototype.id}}</span> {{prototype.name}}
    </a>
    <button class="delete" title="delete Prototype"
            (click)="delete(prototype)">x
    </button>
  </li>
</ul>
