import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FruitSkin } from '../../@interface/fruit-skin';
import { CommonService } from '../../common.service';
import { FruitSkinService } from '../../@service/fruit-skin.service';

@Component({
  selector: 'app-skins-editor',
  templateUrl: './skins-editor.component.html',
  styleUrls: ['./skins-editor.component.css']
})
export class SkinsEditorComponent implements OnInit {

  trueNumber = 1;
  falseNumber = 0;

  constructor(
    public matDialogRef: MatDialogRef<SkinsEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public skin: FruitSkin,
    private fruitSkinService: FruitSkinService
  ) {
  }

  ngOnInit(): void {
  }

  close(): void {
    this.matDialogRef.close();
  }

  save(): void {
    this.fruitSkinService.edit(this.skin).subscribe(skin => {
      this.skin = skin;
      this.close();
    });
  }

}
