import { Component, OnInit } from '@angular/core';
import { Prototype } from '../prototype';
import { PrototypeService } from '../prototype.service';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-prototype',
  templateUrl: './prototype.component.html',
  styleUrls: ['./prototype.component.css']
})
export class PrototypeComponent implements OnInit {

  prototypes: Prototype[] = [];

  constructor(
    private prototypeService: PrototypeService,
    private messageService: MessageService
  ) {
  }

  ngOnInit(): void {
    this.getPrototypes();
  }

  getPrototypes(): void {
    this.prototypeService.getPrototypes(1)
      .subscribe(prototypes => this.prototypes = prototypes);
  }

  add(name: string): void {
    name = name.trim();
    if (!name) {
      return;
    }
    this.prototypeService.addPrototype({name} as Prototype)
      .subscribe(prototype => {
        this.prototypes.push(prototype);
      });
  }

  delete(prototype: Prototype): void {
    this.prototypes = this.prototypes.filter(value => value !== prototype);
    this.prototypeService.deletePrototype(prototype).subscribe();
  }
}
