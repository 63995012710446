import { Component, OnInit } from '@angular/core';
import { Prototype } from '../prototype';
import { Observable, Subject } from 'rxjs';
import { PrototypeService } from '../prototype.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-prototype-search',
  templateUrl: './prototype-search.component.html',
  styleUrls: ['./prototype-search.component.css']
})
export class PrototypeSearchComponent implements OnInit {

  prototypes$: Observable<Prototype[]>;
  private searchTerms = new Subject<string>();

  constructor(private prototypeService: PrototypeService) {
  }

  ngOnInit(): void {
    this.prototypes$ = this.searchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => this.prototypeService.searchPrototypes(term))
    );
  }

  search(term: string): void {
    this.searchTerms.next(term);
  }

}
