import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrototypeComponent } from './prototype/prototype.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PrototypeDetailComponent } from './prototype-detail/prototype-detail.component';
import { InstancesComponent } from './instances/instances.component';
import { InstanceDetailComponent } from './instance-detail/instance-detail.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { SkinsComponent } from './game/skins/skins.component';

const routes: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService]},
  {path: 'prototype', component: PrototypeComponent, canActivate: [AuthGuardService]},
  {path: 'prototype/detail/:id', component: PrototypeDetailComponent, canActivate: [AuthGuardService]},
  {path: 'instances', component: InstancesComponent, canActivate: [AuthGuardService]},
  {path: 'instances/:id', component: InstanceDetailComponent, canActivate: [AuthGuardService]},
  {path: 'game/skins', component: SkinsComponent, canActivate: [AuthGuardService]},
  {path: '**', redirectTo: 'dashboard'}
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
