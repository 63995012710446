import { Injectable } from '@angular/core';
import { MessageService } from '../message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../config.service';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FruitSkin } from '../@interface/fruit-skin';
import { Paginator } from '../@class/paginator';

@Injectable({
  providedIn: 'root'
})
export class FruitSkinService {

  constructor(
    private messageService: MessageService,
    private httpClient: HttpClient,
    private configService: ConfigService
  ) {
  }

  private url = `${this.configService.http().api.baseUrl}/api/fruitSkin/backstage/`;
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  getAll(params = {}): Observable<Paginator<FruitSkin[]>> {
    return this.httpClient
      .get<Paginator<FruitSkin[]>>(this.url, {params})
      .pipe(
        tap(_ => this.log('fetched fruit skins')),
        catchError(this.handleError<Paginator<FruitSkin[]>>('getAllFruitSkins', new Paginator<FruitSkin[]>()))
      );
  }

  edit(skin: FruitSkin): Observable<FruitSkin> {
    return this.httpClient.put<FruitSkin>(this.url, skin)
      .pipe(
        tap(_ => this.log(`update fruit skin: ${skin.skin_id}`)),
        catchError(this.handleError<FruitSkin>('UpdateFruitSkin', {}))
      );
  }

  private log(message: string): void {
    this.messageService.add(`FruitSkinService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
