import { Component, OnInit } from '@angular/core';
import { Prototype } from '../prototype';
import { PrototypeService } from '../prototype.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  prototypes: Prototype[] = [];

  constructor(
    private prototypeService: PrototypeService
  ) {
  }

  ngOnInit(): void {
    this.getPrototypes();
  }

  getPrototypes(): void {
    this.prototypeService.getPrototypes(1)
      .subscribe(prototypes => this.prototypes = prototypes.slice(0, 5));
  }

}
