import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'readable'
})
export class ReadablePipe implements PipeTransform {

  transform(value: unknown, key: string, laguage?: string): string {
    let data = '';
    switch (key) {
      case 'hot':
        data = !!value ? '热门' : '最新';
        break;
      case 'public':
        data = !!value ? '公开' : '私密';
        break;
      case 'shelf':
        data = !!value ? '上架' : '下架';
        break;
      case 'banned':
        data = !!value ? '禁用' : '启用';
        break;
      case 'default':
        data = !!value ? '默认' : '玩家';
        break;
      default:
        data = !!value ? '是' : '否';
    }
    return data;
  }

}
